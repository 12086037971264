export default class Token {
    /**
     * Token informations
     *
     * @param { string } token
     */
    constructor(token) {
        this.token = token;
    }
}
